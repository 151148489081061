import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import "./doctor-patient.css"
import next from '../../assets/img/icons/Next.svg'
import prev from '../../assets/img/icons/Prev.svg'
import sort from '../../assets/img/icons/sort.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setPatientsToRedux } from 'store/actions'
import { PATIENT_DOCTOR_STATUS, PATIENT_DOCTOR_STATUS_TEXT } from 'consts'
import SubNavbar from 'components/Navbars/SubNavbar'
import ConfirmModal from 'components/Modals/confirm-modal'
import { t } from 'multi-language/multi-lang.service'

function PatientsRequest({user}) {
  const [countPatient, setCountPatient] = useState(0)
  const [keySort, setKeySort] = useState('pending')
  const dispatch = useDispatch()
  const { patients } = useSelector(state => state)
  const patientsThatRemovedDoctor = getPatientsThatRemovedDoctor()

  useEffect(()=>{
    setCountPatient(0)
  },[keySort])

  const updatePatientsAsChecked = () => {
    const updatedPatients = patients.map((patient)=> ( {...patient,status_checked:true}))
    dispatch(setPatientsToRedux(updatedPatients))
  }

  function getPatientsThatRemovedDoctor(){
    return patients.filter((p) => !p.status_checked && p.status === PATIENT_DOCTOR_STATUS.REMOVED_BY_PATIENT)
  }

  const updateChecked = async () => {
    const idList = patients.filter((p) => !p.status_checked).map((patient)=> Number(patient.id))
    if(!idList.length) return
    await axios.post(`${process.env.REACT_APP_API_URL}/app/doctor/update-relation-status-checked-by-patient`,
        { patient_removed: idList, doctor_id: user.data.id, status_checked: true },
        { headers: { 'Authorization': `Bearer ${user.jwtToken}` } }).then((res) => {
    })
    updatePatientsAsChecked()
   }

  useEffect(() => {
     if(!patientsThatRemovedDoctor.length){
        updateChecked()
     }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const changeStatus = async(id, isAccepted) => {
    const status = isAccepted ? 'approved' : 'denied';
    const status_checked = false
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/app/doctor/update-relation-status`,{doctor_id: user.data.id, patient_id:id, status},{ headers: {'Authorization': `Bearer ${user.jwtToken}`} }) 
      await axios.post(`${process.env.REACT_APP_API_URL}/app/doctor/update-status-checked`,{doctor_id: user.data.id,patient_id:id, status_checked},{ headers: {'Authorization': `Bearer ${user.jwtToken}`} })
      const updatePatient = [...patients].map(p => p.id === id ? {...p,status} : p)
      dispatch( setPatientsToRedux(updatePatient) )
    } catch (error) {
      console.log('error',error)
    }
  }

  const requestActions = (id) => (
    <div className='req-btns'>
      <button id='accept-request' onClick={()=>changeStatus(id,true)} className='btn btn-outline-success'>{t('general_words.accept')}</button>
      <button id='decline-request' onClick={()=>changeStatus(id)} className='btn btn-outline-danger'>{t('general_words.decline')}</button>
    </div>
  )

  const sortByKeyword = (a,b) => {
    if(keySort === 'pending') return +(b.status==='pending') - +(a.status==='pending') 
      || +(b.status==='approved') - +(a.status==='approved') 
      || +(b.status==='removed-by-doctor') - +(a.status==='removed-by-doctor') 
      || +(b.status==='removed-by-patient') - +(a.status==='removed-by-patient')
      || +(b.status==='denied') - +(a.status==='denied')
    if(keySort === 'date_desc') return new Date(b.statusUpdatedDate) - new Date(a.statusUpdatedDate)
    if(keySort === 'date_asc') return new Date(a.statusUpdatedDate) - new Date(b.statusUpdatedDate)
    if(keySort === 'name_asc') return a.firstName.localeCompare(b.firstName)
    if(keySort === 'name_desc') return b.firstName.localeCompare(a.firstName)
  }
  
  return (
    <div id='patient-request'>
        {patientsThatRemovedDoctor.length > 0 && 
            <ConfirmModal
                text={`${patientsThatRemovedDoctor.map(p => `${p.firstName} ${p.lastName}`).join(', ')} ${t('doctor.patient_removed')}`}
                onConfirm={updateChecked}
                onCancel={updateChecked}
                displayCancelButton={false}
                confirmButtonText='OK'
            />
        }
        <SubNavbar title={t('pages.patient_requests.page_name')} subPages={[t('pages.patient_requests.page_name')]}/>
        <Row className='content-requests p-5 card m-3 flex-nowrap'>
            <table>
              <thead>
                <tr>
                  <td><span>{t('pages.patient_requests.watch')}<img onClick={()=>setKeySort(keySort === 'name_asc' ? 'name_desc' : 'name_asc')} src={sort} alt='sort' /></span></td>
                  <td className='table-middle'><span>{t('pages.patient_requests.date')}<img onClick={()=>setKeySort(keySort === 'date_desc' ? 'date_asc' : 'date_desc')} src={sort} alt='sort' /></span></td>
                  <td><span>{t('general_words.status')}<img onClick={()=>setKeySort('pending')} src={sort} alt='sort' /></span></td>
                </tr>
              </thead>
              <tbody>
                {patients.sort((a,b) => sortByKeyword(a,b)).slice(countPatient,countPatient+10).map(p => <tr key={p.id}>
                  <td>{`${p.nickname ?` ${p.firstName} (${p.nickname}) ${p.lastName}`: p.firstName + ' ' +p.lastName}`}</td>
                  <td className='table-middle'>{moment(p.statusUpdatedDate).format('MMMM D, YYYY HH:mm')}</td>
                  <td className='table-right'>{p.status !== 'pending' ? PATIENT_DOCTOR_STATUS_TEXT[p.status] : requestActions(p.id)}</td>
                </tr>)}
              </tbody>
            </table>
            <div className='brows-pages'>
                <img onClick={()=>setCountPatient(countPatient-10)} className={countPatient < 1 ? 'disabled-arrow' : ''} src={prev} alt='arrow' />
                <p>{countPatient} - {(patients.length - countPatient < 10) ? countPatient + (patients.length - countPatient) : countPatient+10} {t('general_words.of')} {patients.length}</p>
                <img onClick={()=>setCountPatient(countPatient+10)} className={((countPatient +10) >=  patients.length) ? 'disabled-arrow' : ''} src={next} alt='arrow' />
            </div>
        </Row>
    </div>
  )
}

export default PatientsRequest