import { Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Collapse } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Thresholds from "pages/profile/patient/Thresholds";
import MarketPlace from "pages/reports/MarketPlace";
import MedicalInfo from "pages/profile/patient/Medical";
import Archive from "pages/reports/archive";
import { PersonalInfo } from "pages/profile/patient/PersonalInfo";
import Medications from "pages/profile/patient/medications";
import MeasuremetReminders from "pages/profile/patient/measurement-reminders";
import { useEffect } from "react";
import { getPatientAppConfig } from "api/api-manager";
import { t } from "multi-language/multi-lang.service";

export const SUB_P_PAGES = {
    DASHBOARD: 'dashboard',
    PERSONAL_DATA: 'personal_data',
    MEDICAL_DATA: 'medical_data',
    MEDICATIONS: 'medications',
    MEASUREMENTS: 'measurements',
    THRESHOLDS: 'thresholds',
    REPORTS: 'reports'
}

export const ARCHIVE_ROUTE = 'archive'

export default function SubNavbarDoctor({ patient, subLocation, jwtToken , appConfigs }) {

    useEffect(() => {
        if(appConfigs) return 
            getPatientAppConfig(patient.id)
    }, [])

    const getSubPages = () => {
        const showTrhesholds = appConfigs?.configuration?.alerts?.thresholds === 'on'
        const subPagesFilteredByThresholdsConfig = Object.values(SUB_P_PAGES).filter(s => s !== SUB_P_PAGES.THRESHOLDS || showTrhesholds)
        return subPagesFilteredByThresholdsConfig
    }
    
    const navigate = useNavigate()
    const setSubRouteParam = (route, dates) => {
        navigate(`/patient/${patient.id}/${route}`, { state: { dates } })
    }

    const subMenu = () => (

        <Navbar color="white" light expand="md" style={{ paddingLeft: '38px' }} /*   className="border" */ >
            <NavbarToggler />
            <Collapse isOpen navbar>
                <Nav className="mr-auto w-100" navbar>
                    {getSubPages().map(page => page === subLocation ?
                        <NavbarBrand id={`route-${page}`} key={page} style={{ cursor: 'pointer' }} >{t(`pages.${subLocation}.page_name`)}</NavbarBrand>
                        :
                        <NavItem style={{ cursor: 'pointer' }} key={page}>
                            <div id={`route-${page}`} className="nav-link" onClick={() => setSubRouteParam(page)} tag={Link}>{t(`pages.${page}.page_name`)}</div>
                        </NavItem>
                    )}
                </Nav>
            </Collapse>
        </Navbar>
    )

    const renderSubPage = () => {
        if (subLocation === SUB_P_PAGES.PERSONAL_DATA) return <PersonalInfo user={patient} role='doctor' />
        if (subLocation === SUB_P_PAGES.MEDICAL_DATA) return <MedicalInfo patient={patient} jwtToken={jwtToken} role={'doctor'} />
        if (subLocation === SUB_P_PAGES.MEDICATIONS) return <Medications user={patient} role={'doctor'} />
        if (subLocation === SUB_P_PAGES.MEASUREMENTS) return <MeasuremetReminders user={patient} role={'doctor'} />
        if (subLocation === SUB_P_PAGES.THRESHOLDS) return <Thresholds patient={patient} jwtToken={jwtToken} role={'doctor'} />
        if (subLocation === SUB_P_PAGES.REPORTS) return <MarketPlace patient={patient} setSubRouteParam={setSubRouteParam} appConfigs={appConfigs} role={'doctor'} />
        if (subLocation === ARCHIVE_ROUTE) return <Archive user={patient} jwtToken={jwtToken} role={'doctor'} />
    }

    return (
        <>
            {subMenu()}
            {renderSubPage()}
        </>
    )
}