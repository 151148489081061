import React, { useEffect, useState } from 'react'
import { setSocketPatientsData, setPatientChannels, setSocketConnectionId } from 'store/actions';
import { CHANNEL_MESSAGE_ACTION, MESSAGE_TYPE, USER_TYPES, MESSAGE_PLATFORM, SOCKET_MESSAGE_ACTION, FRAMES_MESSAGE_ACTION, REALTIME_MESSAGE_ACTION, ALERT_MESSAGE_ACTION } from 'consts';
import { useDispatch, useSelector } from 'react-redux'
import useWebSocket from 'react-use-websocket';
import { Subject } from 'rxjs';
import { convertSocketKeys } from 'utils';
export const ecgDataStream = new Subject();

export const WebsocketDoctor = ({ user }) => {
  const { sendMessage, lastMessage } = useWebSocket(`${process.env.REACT_APP_SOCKET_URL}?id=${user?.data.id}&user_type=${user.type}&platform=portal&jwtToken=${user.jwtToken}`, { share: true })
  const [connectionId, setConnectionId] = useState()
  const localPatientId = localStorage.getItem('currentPatientId');
  const dispatch = useDispatch()
  const { patients } = useSelector(state => state)
  const approvedPatients = patients.filter((p) => p.status === 'approved').map((p) => (
    { ...p, name: `${p.firstName} ${p.lastName}` }
  ))

  useEffect(() => {
      processMessage(lastMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage])

  useEffect(() => {
    if (connectionId && approvedPatients.length) { //answer for doctor requst connection
      invite(connectionId)
      dispatch(setSocketConnectionId(connectionId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedPatients.length, connectionId])

  const processMessage = (msg) => {
    // console.log('meeee',msg)
    if(!msg?.data) return
    try {
      const messageData = JSON.parse(msg.data)
      switch(messageData.type){
        case MESSAGE_TYPE.SOCKET:
          processSocketMessage(messageData)
          break
        
        case MESSAGE_TYPE.CHANNEL:
          processChannelMessage(messageData)
          break
        
        case MESSAGE_TYPE.FRAMES:
          processFramesMessage(messageData)
          break
        
        case MESSAGE_TYPE.REALTIME:
          processRealTimeMessage(messageData)
          break
          case MESSAGE_TYPE.ALERT:
            processAlertMessage(messageData)
            break

        default: console.warn('unknown socket message', msg)
      }

    } catch (error) {
      console.log('error in process message', error)
    }
  }

  function processSocketMessage(msg){
    switch(msg.action){
      case SOCKET_MESSAGE_ACTION.ACCEPT:
        setConnectionId(msg.data.uuid_connection)
        break
        
        default: break
      }
  }

  function processChannelMessage(msg){
    const {action, data} = msg
    switch(action){
      case CHANNEL_MESSAGE_ACTION.ACCEPT_BULK_INVITE:
        const patientsChannels = {}
        data.forEach((item) => patientsChannels[item.patientId] = item?.uuid_channel) //item.id
        dispatch(setPatientChannels(patientsChannels))
        break

      default: break
    }
  }

  function processFramesMessage(msg){
    const {action, from, data} = msg
    switch(action){
      case FRAMES_MESSAGE_ACTION.SEND:
        const dataConvertedKeys = convertSocketKeys(data)
        const alertsConvertedKeys = convertSocketKeys(data?.onAlert)
        dispatch(setSocketPatientsData({ [from.source_id]: {...dataConvertedKeys, onAlert: alertsConvertedKeys, isFromApp: true} })) //the key is patient id
        break

      default: break
    }
  }

  function processRealTimeMessage(msg){
    const {action, from, data} = msg
    switch(action){
      case REALTIME_MESSAGE_ACTION.START:
      case REALTIME_MESSAGE_ACTION.END:
        const source_id = from.source_id
        if (source_id === Number(localPatientId)) {
          ecgDataStream.next(data)
        }
        break

      default: break
    }
  }

  function processAlertMessage(msg){
    const {action, from, data} = msg
    switch(action){
      case ALERT_MESSAGE_ACTION.SEND:
        const dataConvertedKeys = convertSocketKeys(data)
        const alertsConvertedKeys = convertSocketKeys(data?.onAlert)
        dispatch(setSocketPatientsData({ [from.source_id]: {...dataConvertedKeys, onAlert: alertsConvertedKeys, isFromApp: true} })) //the key is patient id
        break

      default: break
    }
  }

  const getPatientsSocketAddress = () => {
    const patientsIds = approvedPatients.map(patient => ({
      target_id: patient.id,
      target_type: USER_TYPES.PATIENT,
      platform: MESSAGE_PLATFORM.MOBILE
    }))
    return patientsIds
  }

  const invite = (uuid_connection) => {
    try {
      sendMessage(JSON.stringify({
        type: MESSAGE_TYPE.CHANNEL,
        action: CHANNEL_MESSAGE_ACTION.BULK_INVITE,
        from: {
          source_id: user.data.id,
          source_type: user.type,
          connection_id: uuid_connection,
          platform: MESSAGE_PLATFORM.PORTAL,
        },
        to: getPatientsSocketAddress(),
      }))
    } catch (err) {
      console.log('err', err)
    }
  }

  return (<div />)
}

export default WebsocketDoctor