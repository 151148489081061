import React,{useEffect} from 'react'
import { drawBloodPressureChart, drawPPGChart, drawRespoiratoryChart, drawGlucoseChart, drawSpO2Chart, drawTempChart, drawWeightChart, drawRSIChart, drawNightRespiratoryChart } from '@cardiacsense/cs-charts-module'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedDate } from 'store/actions'
import { emptyMonthlyData } from 'variables/charts'
import { useWindowSize } from 'hooks/useOnResize'
import HeartLoader from 'components/icons/heart-loader/heart-loader'
import { MEASUREMENT_KEYS } from 'consts'

export function MonthlyChart({patientId, type, thresholds, dayStr, unit, statusThresholds}) {
    const {user, monthlyData:allMonthsData} = useSelector((state)=> state)
    const monthlyData = allMonthsData?.[patientId]?.[dayStr] || emptyMonthlyData(dayStr)
    const {onLoad} = monthlyData;
    const height = 450;
    const [screenWidth] = useWindowSize();
    const dispatch = useDispatch()

    const thresholdsStatus = user?.data.appConfigs?.configuration?.alerts?.thresholds || statusThresholds
    const chart = {
        [MEASUREMENT_KEYS.PULSE_RATE]: {draw:drawPPGChart, data: [monthlyData.pulse_data]},
        // [MEASUREMENT_KEYS.RESPIRATORY_RATE]: {draw:drawRespoiratoryChart, data:[monthlyData.respiratory_data]},
        [MEASUREMENT_KEYS.RESPIRATION_ANALYSIS]: {draw: drawRespoiratoryChart, data:[monthlyData.min_night_respiratory_data, monthlyData.avg_night_respiratory_data, monthlyData.max_night_respiratory_data]},
        [MEASUREMENT_KEYS.NIGHT_RRV]: { draw: drawRSIChart, data:[monthlyData.night_rrv_data] },
        [MEASUREMENT_KEYS.BLOOD_PRESSURE]: {
            draw:drawBloodPressureChart, 
            data:[ monthlyData.systolic_data, monthlyData.systolic_manual_data, monthlyData.systolic_external_data, monthlyData.diastolic_data, monthlyData.diastolic_manual_data, monthlyData.diastolic_external_data ]
        },
        [MEASUREMENT_KEYS.SPO2]: {draw:drawSpO2Chart, data:[ monthlyData.oxygenSaturation_data, monthlyData.oxygenSaturation_manual_data, monthlyData.oxygenSaturation_external_data ]},
        [MEASUREMENT_KEYS.BODY_TEMP]: {draw:drawTempChart, data:[ monthlyData.temperature_data, monthlyData.temperature_manual_data, monthlyData.temperature_external_data ]},
        [MEASUREMENT_KEYS.WEIGHT]: {draw:drawWeightChart, data:[ monthlyData.weight_data, monthlyData.weight_manual_data ]},
        [MEASUREMENT_KEYS.GLUCOSE]: {draw:drawGlucoseChart, data:[ monthlyData.glucose_data, monthlyData.glucose_manual_data ]}
    }
    
    const setDate = (date) => {
        dispatch( setSelectedDate(date) )
    }
    
    useEffect(() => {
        if(monthlyData){
            const {draw, data} = chart[type]
            const contentWidth = document.getElementsByClassName("chart-box")[0].clientWidth - 30
            const width = contentWidth > 1180 ? contentWidth : 1190
            const translateFunc = null
            const svgNode = draw(translateFunc, ...data, thresholds, (date) => setDate(new Date(date)), width, height, unit, thresholdsStatus)
            svgNode.classList.add("d-print-none");
            const chartNode = document.getElementsByClassName(`${type}-chart`)[0]
            chartNode.replaceChild( svgNode, chartNode.firstChild )
            // draw the same chart beat smaller for printing in portal
            const printWidth = 1200
            const printSvgNode = draw(translateFunc, ...data, thresholds, (date) => setDate(new Date(date)), printWidth, height, unit, thresholdsStatus)
            printSvgNode.classList.add("print-daily-show");
            chartNode.replaceChild( printSvgNode, chartNode.childNodes[1] );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthlyData, screenWidth])

    return <div id={`${type}-chart`} className={`${type}-chart`}>
        <div className="svg chart" />
        <div>{onLoad && <HeartLoader size={300} />}</div>
    </div>

}

export default MonthlyChart