import axios from 'axios';
import { t } from 'multi-language/multi-lang.service';
import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { Card, CardBody, Form, Button, Input, InputGroup, Row, Col } from "reactstrap";

export default function ForgotPassword() {
    const [email, setEmail] = useState('')
    const [emailSent, setEmailSent] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const navigate = useNavigate()

    const handleClick = async () => {
        if (emailSent) {
            navigate('/auth/login')
        }
        else {
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/app/user/forgot-password`, { email })
                setEmailSent(true)
                setErrorMsg()
            }
            catch (err) {
                setErrorMsg(t('pages.login.errors.enter_valid_mail'))
            }
        }
    }

    const renderSentMessage = () =>
        <p>
            {`${t('pages.login.mail_sent')}: ${email}.`}
            <br />
            {t('pages.login.not_received')}
        </p>

    const renderForm = () =>
        <>
            <div className="mb-3">{t('pages.login.enter_mail')}</div>
            <Row className="mb-3">
                <Col xs="4">
                    <div style={{ fontSize: '28px' }} >
                        {t('personal_info.email')}
                    </div>
                </Col>
                <Col>
                    <InputGroup className="input-group-alternative">
                        <div className='input-group-append'>
                            <div className='input-group-text'>
                                <i className="ni ni-email-83" />
                            </div>
                        </div>
                        <Input
                            id="input-email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder={t('personal_info.email')}
                            type="email"
                            autoComplete="new-email"
                        />
                    </InputGroup>
                </Col>
            </Row>
        </>

    return (
        <>
            <Col id='forgot-password' lg="6" md="7">
                <div style={{ fontSize: '34px' }} className="text-white text-capitalize">{t('pages.login.forgot_pass')}</div>
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <Form role="form">
                            {emailSent ? renderSentMessage() : renderForm()}
                        </Form>
                        <Row>
                            <Col>
                                <small className="text-danger">{errorMsg}</small>
                            </Col>
                            <Col xl="auto">
                                <Button id='send-forgot-mail' onClick={handleClick} className="bg-blue text-white" type="button">
                                    {t(`pages.login.${emailSent ? 'back_to_login' : 'send_mail'}`)}
                                </Button>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
                <Row className="mt-3">
                    <Col xs="6">
                        <Link to='/auth/login' className="text-link">
                            {!emailSent && <small>{t('pages.login.back_to_login')}</small>}
                        </Link>
                    </Col>
                </Row>
            </Col>
        </>
    )
}
