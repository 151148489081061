import { t } from 'multi-language/multi-lang.service'
import React from 'react'

function DetectionsInfoFlag() {
    return (
        <span className='info-flag mx-1'>
            ?
            <span className='info-text detection-wrap'>
                <div className="info-detection info-ppg">
                    <span className="detection-legend" />
                    <span>{t('modals.detections_info.ppg')}</span>
                </div>
                <div className="info-detection">
                    <span className="detection-legend" />
                    <span>{t('modals.detections_info.ecg')}</span>
                </div>
            </span>
        </span>
    )
}

export default DetectionsInfoFlag