import { SET_MEASUREMENT_REMIDERS } from "./action-types"

export function setMonthlyData(monthlyData,date,onLoad,patientId) {
    return {
        type:'SET_MONTHLY_DATA', 
        payload: { 
            patientId,
            date, 
            data : { ...monthlyData,  onLoad } 
        }
    }
}

export function setSelectedDate(newDate){
    return {type:'SET_DATE', payload:newDate}
}

export function setPatientsToRedux(patients){
    return {type:'SET_PATIENTS', payload:{patients}}
}

export function setPatientAlerts(newAlertsData){
    return {type:'SET_PATIENT_ALERTS', payload:newAlertsData}
}

export const setUserToState = (userData) => {
    return {type:'SET_USER_DATA', payload:userData}
}

export function setUnsavedChanges(callback){
    return {
        type:'SET_UNSAVED_CHANGES',
        payload: callback? {
            callback,
        }
        :null
    }
}

export function setPatientsConfiguration(patientId, configurations){
    return {
        type:'SET_PATIENTS_CONFIGURATION',
        payload: {patientId, configurations}
    }
}

export function setDailyData(patientId, date, data){
    return {
        type:'SET_DAILY_DATA',
        payload: {patientId, date, data}
    }
}

export function setSocketConnectionId(connectionId){
    return {
        type:'SET_CONNECTION_ID',
        payload: connectionId
    }
}

export function setPatientChannels(patientsChannels){
    return {
        type:'SET_PATIENT_CHANNELS',
        payload: patientsChannels
    }
}

export const updatePatients = (patient) => ({
    type:'SET_UPDATE_PATIENTS',
    payload: {patient}
})

export function setMedicationsData(patientsMedication){
    return {
        type:'SET_MEDICATIONS_DATA',
        payload: patientsMedication
    }
}

export function setMeasurementReminders(patientsReminders){
    return {
        type: SET_MEASUREMENT_REMIDERS,
        payload: patientsReminders
    }
}

export function setMeasurementsFromDB(measurements){
    return {
        type:'SET_DB_MEASUREMENTS',
        payload: measurements
    }
}

export function setSocketPatientsData(socketPatientsData){
    return {
        type:'SET_PATIENTS_SOCKET_DATA',
        payload: socketPatientsData
    }
}

export function setSocketRealtimeData(socketRealtimeData){
    return {
        type:'SET_REALTIME_SOCKET_DATA',
        payload: socketRealtimeData
    }
}

export function setPhysicians(physicians){
    return {
        
        type: 'SET_PHYSICIANS',
        payload:physicians
    }
}

export function setCountries(countries){
    return {
        type: 'SET_COUNTRIES',
        payload: countries
    }
}

export function setVersions(versions){
    return {
        type: 'SET_VERSIONS',
        payload: versions
    }
}

export function setAppConfigurations(configurations){
    return {
        type: 'SET_APP_CONFIGURATIONS',
        payload: configurations
    }
}

export function setFotaList(fotaList){
    return {
        type: 'SET_FOTA_LIST',
        payload: fotaList
    }
}

