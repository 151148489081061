import { t } from 'multi-language/multi-lang.service';
import React,{useState,useEffect} from 'react'
import { ReadyState } from 'react-use-websocket';
import {Card, CardBody, CardTitle} from "reactstrap";

const Message = ({sender,title,content, timestamp, index}) =>
<div id='msg-content' style={{fontSize:'14px', paddingBottom:'20px',paddingLeft:'10px', marginLeft:'6%' ,borderLeft:'solid 1px black', position:'relative'}}>
    <div className="message-circle" style={{height:'13px', width:'13px',border:'solid 2px #3FA842', position:'absolute',left:-7,top:6,borderRadius:'50%', backgroundColor:'white'}} />
    {!index && <div className="border-hider" style={{height:'6px', width:'1px', position:'absolute',left:-1,top:0, backgroundColor:'white'}} />}
    <h3 id='msg-sender'>{sender}</h3>
    {title}
    <div className="text-muted">{timestamp.toLocaleString('en-GB', {hour12: false })}</div>
</div>

export default function MessagesBox({readyState,/*user*/}) {

//     const welcomeMsg = {
//         sender:"CardiacSense team",
//         title:`Welcome to your ${user.type==='patient'?'patient':'doctor'} portal`,
//         timestamp:new Date(1644929482277)
// }

    const [messages, setMessages] = useState([/* welcomeMsg */])

    useEffect(() => {
        const connectionStatus = {
            [ReadyState.CONNECTING]: 'Connecting',
            [ReadyState.OPEN]: 'Open',
            [ReadyState.CLOSING]: 'Closing',
            [ReadyState.CLOSED]: 'Closed',
            [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
          }[readyState];
        if(readyState===(ReadyState.OPEN || ReadyState.CLOSED))
        {
            const newMsg = {sender:'Websocket', title:`The WebSocket is currently ${connectionStatus}`, timestamp:new Date()}
            setMessages(current=>[newMsg,...current]) 
        }

    }, [readyState])
    //console.log('msgs rendered',readyState)
    return (
        <Card style={{height:'81%'/* '100%' */}} className="card-stats mb-4 mb-xl-4 d-print-none">
            <CardBody style={{padding:'9px 3px 9px 0px'}}>
                <CardTitle style={{paddingBottom:'5px',margin:'0px 18px 10px', borderBottom:'solid 1px rgba(0, 0, 0, .2)'}}>
                    {t('dashboard.boxes.messages.title')}
                </CardTitle>
                <div style={{ overflow:'hidden' , height:'85%'}}>
                    {messages.map((msg,i)=><Message {...msg} key={i} index={i} />)}                    
                </div>
                
            </CardBody>
        </Card>
    )
}
