import moment from 'moment';
import _ from "lodash"
import { CONFIG_OPTIONS, SOCKET_TO_DASHBOARD_KEYS } from 'consts';

export const getFirstDayInMonth = (date) => {
  const firstDay = moment(date).startOf('month').format('YYYY-MM-DD');
  return firstDay
}

export function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date).toLocaleDateString());
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export function timeZoneConvert(time, addOrLess) {//convert the time to bring the correct data, in the utc time
  const tzDifference = new Date().getTimezoneOffset()*-1;
  const offsetTime = (addOrLess === 'less') ? new Date(new Date(time).getTime() - ( tzDifference *60*1000 )) : new Date(new Date(time).getTime() + ( tzDifference *60*1000 ));
  return offsetTime
}

const convertToCamelCase = (str) => str.replace(/_[a-z]/g, (match) => `${match[1].toUpperCase()}`)
export function convertKeysToCamelCase (obj) {
  const res = {}
  _.each(_.keys(obj), (key) => {
    res[convertToCamelCase(key)] = obj[key]
  })
  return res
}


const convertToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).replace(/^_/, '')


export const convertCamelKeysToSnakeCase = (obj) => {
  const res = {}
  _.each(_.keys(obj), (key) => {
    res[convertToSnakeCase(key)] = obj[key]
  })
  return res
}

export const convertSocketKeys = (obj) => {
  const res = {}
  _.each(_.keys(obj), (key) => {
    res[SOCKET_TO_DASHBOARD_KEYS[key] || key] = obj[key]
  })
  return res
}

export const allEqual = arr => arr.every( v => v === arr[0] || v === 0 )

export const removeZeroes = (arr) => arr.filter( e => e !== 0)

export const convertDecimalSecToTime = (decimalSeconds) => {
  if (!decimalSeconds || isNaN(decimalSeconds)) {
    return '---';
  }
  const rawMinutes = Math.floor(decimalSeconds / 60);
  const hours = Math.floor(rawMinutes / 60);
  const remainingMinutes = rawMinutes % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = remainingMinutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export const fixNumAndRemoveDecimalZero = (value, toFix) => {
  const [intVal, decimalVal] = `${value}`.split('.')
  return (Number(decimalVal) && toFix) ? `${intVal}.${decimalVal.slice(0, toFix)}` : intVal
}

export function isKeyValueObject(obj) {
  if (typeof obj === 'object' && obj !== null) {
    // Check if it is a key-value object (not an array or a function)
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  return false;
}

export const isString = (str) => typeof str === 'string'

export const isConfigOn = (config) => {
  return config === CONFIG_OPTIONS.ON
}

export const isConfigWatch = (config) => {
  return config === CONFIG_OPTIONS.WATCH
}