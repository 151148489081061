import React, { useRef, useState } from "react";
import _ from 'lodash'
import { Container, Row, Col } from "reactstrap";
import { MeasureBox, MassagesBox, NotificationsBox, ChartBox } from 'components/Cards'
import { CONFIG_OPTIONS, setupMesutments } from "consts.js"
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { DailyChart } from '@cardiacsense/report-templates-npm'
import MonthlyChart from "components/Charts/Monthly";
import { useSelector } from "react-redux";
import CaloriesAndSteps from "components/Cards/calories-and-steps";
import { useReactToPrint } from 'react-to-print';
import { DASHBOARD_FIRST_ROW, DASHBOARD_SECOND_ROW } from "consts";
import { t } from "multi-language/multi-lang.service";


const Dashboard = ({ user, patient }) => {

  const { measurementsFromDB, socketPatientsData, patientsConfiguration } = useSelector(state => state)
  const [monthlyCharts, setMonthlyCharts] = useState([])
  const chartBoxRef = useRef()
  const patientId = patient?.id
  const patientMeasurments = socketPatientsData?.[patientId]
  const measurments = setupMesutments(patient, patientMeasurments, measurementsFromDB[patientId])
  const units = _.mapValues(measurments, ({ unit }) => unit)

  const configurations = patientsConfiguration[patientId]?.configuration

  const pageStyle = `
  @page {
    size: 80mm 50mm;
    margin: 100px;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

  const handleBoxClick = (chartKey) => {
    const chartIndex = monthlyCharts.findIndex(key => key === chartKey)
    if (chartIndex > -1) {
      const removed = [...monthlyCharts]
      removed.splice(chartIndex, 1)
      setMonthlyCharts(removed)
    } else {
      setMonthlyCharts(prev => [chartKey, ...prev])
    }
  }

  const handlePrint = useReactToPrint({
    pageStyle: () => pageStyle,
    content: () => chartBoxRef.current,
  });

  const isConfigOff = (item) => {
    const key = measurments[item]?.configKey || measurments[item]?.key
    return configurations?.measurements?.[key] === CONFIG_OPTIONS.OFF
  }

  if(!configurations) return
  return (
    <div ref={chartBoxRef}>
      <Container fluid style={{ maxWidth: '2000px' }}>

        <Row className="mt-5" style={{ paddingLeft: '10px' }} >
          <Col xl="9">
            <Row>
              <Col style={{ padding: '0px 4px' }}>
                <NotificationsBox alerts={measurments.alerts} />
              </Col>
              {DASHBOARD_FIRST_ROW.map((item) =>
                <Col key={measurments[item]?.title} style={{ padding: '0px 4px' }} >
                  <MeasureBox
                    measurementKey={item}
                    {...measurments[item]}
                    disabled={isConfigOff(item)}
                    onClick={handleBoxClick}
                  />
                </Col>
              )}
            </Row>
            <Row className="mt-2">
              {DASHBOARD_SECOND_ROW.map((item) => <>
                <Col key={measurments[item]?.title} style={{ padding: '0px 4px' }} >
                  <MeasureBox
                    measurementKey={item}
                    {...measurments[item]}
                    disabled={isConfigOff(item)}
                    onClick={handleBoxClick} />
                </Col></>
              )}
            </Row>
          </Col>
          <Col xl="3">
            <CaloriesAndSteps patientId={patientId} steps={measurments.steps} calories={measurments.calories} configurations={configurations} />
            <MassagesBox readyState={0/* readyState  user={user} */} />
          </Col>

        </Row>

        <TransitionGroup>
          {monthlyCharts.map(key =>
            <CSSTransition key={key} timeout={500} classNames="chart-card">
              <ChartBox key={key} title={t(`measurements.${key}.monthly_title`)} token={user.jwtToken} handlePrint={handlePrint} patientId={patientId} role={user.type}>
                <MonthlyChart
                  patientId={patientId}
                  type={key}
                  thresholds={patient.thresholds}
                  unit={units[key]} />
              </ChartBox>
            </CSSTransition>
          )}
        </TransitionGroup>
        <ChartBox title={t('dashboard.daily.title')} token={user.jwtToken} handlePrint={handlePrint} patientId={patientId} role={user.type}>
          <DailyChart units={units} configurations={configurations} />
        </ChartBox>
      </Container>
    </div>
  );
};

export default Dashboard