import React from "react";
import SubNavbar from "components/Navbars/SubNavbar";
import { t } from "multi-language/multi-lang.service";
import Dashboard from "./dashboard";

const PatientDashboard = (props) => {
  return (
    <div>
      <SubNavbar title={t('pages.home.page_name')} subPages={[t('pages.home.page_name')]} />
      <Dashboard user={props?.user} patient={props?.user?.data} />
    </div>
  );
};
export default PatientDashboard