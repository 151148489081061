import React from 'react'
import deniedImage from "../../../assets/img/theme/doctor-denied.png"
import { t } from 'multi-language/multi-lang.service'

function DoctorDeniedScreen({user, setUser}) {

  const reRegistration = () => {
    setUser({...user, data:{...user.data, approvalState: null}})
  }

  return (
    <div id='denied-page-try' className='denied-section card row'>
    <div className='denied-content text-center'>
      <h1 className='denied-title'>{t('doctor.registration.status.try_again')}</h1>
      <div className='text-center'><span className='pointer register-link' onClick={reRegistration}>{`${t('doctor.registration.back_to_form')} >>`}</span></div>
      <img src={deniedImage} alt='denied-img'/>
    </div>
  </div>
  )
}

export default DoctorDeniedScreen