import React from 'react'
import ReminderIcon from '../../../assets/img/icons/remindmelater.svg'
import OffwristIcon from '../../../assets/img/icons/offwrist.svg'
import AppDisconnectIcon from '../../../assets/img/icons/app_disconnected.svg'
import WatchDisconnected from '../../../assets/img/icons/watch_diconnected.svg'
import AlertIcon from '../../../assets/img/icons/alerton.svg'
import BatteryIconComponent from 'components/icons/battery-icon/battery-icon.component'
import './patient-status.scss'
import { parseBoolean } from 'utils/formaters'
import moment from 'moment'
import { t } from 'multi-language/multi-lang.service'

function PatientStatusComponent({ sockeData, dbAlerts, remindAlerts }) {

    const renderAlertStatus = () => {
        let src;
        let title;
        if (remindAlerts?.length) {
            src = ReminderIcon
            title = t('doctor.patient_status.snooze')
        }

        //checking if there are alerts from socket or from DB
        if ((sockeData?.onAlert && Object.values(sockeData?.onAlert)?.length) || dbAlerts?.length) {
            src = AlertIcon
            title = t('doctor.patient_status.alerts')
        }
        return <img className={`status-icon alert-icon ${!src && 'hide'}`} src={src} alt={title} title={title} />
    }

    const renderBatteryStatus = () => {
        let percent = sockeData?.batteryPercentage?.value || 0
        let color = '#1D3378'
        if(percent <= 25) color = '#F2E500'
        if(percent <= 10) color = '#EF3054'
        return <div className={`status-icon ${percent < 1 && 'hide'}`}><BatteryIconComponent percent={percent} fill={color} /></div>
    }

    const renderConnectStatus = () => {
        let src = AppDisconnectIcon;
        let title = t('doctor.patient_status.app_disconnect');
        let offWristTimeStatus;
        const isOffwrist = parseBoolean(sockeData?.offWrist?.value)
        if (sockeData) src = null//the app is connected

        if (sockeData?.connectionStatus?.value === 0) {//the watch is not connected to the app
            src = WatchDisconnected
            title = t('doctor.patient_status.watch_disconnect')
        }
        else if (isOffwrist) {
            src = OffwristIcon
            title = t('doctor.patient_status.offwrist')
            const currentAppTime = sockeData?.offWrist?.currentAppTime
            const lastChange = sockeData?.offWrist?.lastChange
            const offWristHours = moment(currentAppTime).diff(moment(lastChange), 'hours')
            if(offWristHours){
                offWristTimeStatus = `${offWristHours}H`
            }
            if(offWristHours > 24){//display offwrist time in days
                offWristTimeStatus = `+${Math.floor(offWristHours/24)}D`
            }
        }
        return <div className='wrap-status-icon'>
                    <img className={`status-icon ${!src && 'hide'}`} src={src} alt={title} title={title} />
                    {offWristTimeStatus && <div className='offwrist-time'>{offWristTimeStatus}</div>}
                </div>
    }
    return (
        <div id='patient-status' className='patient-status-row'>
            <div id='alert-status' className="patient-status-col alert-status">{renderAlertStatus()}</div>
            <div id='battery-status' className="patient-status-col battery-status">{renderBatteryStatus()}</div>
            <div id='connect-status' className="patient-status-col connect-status">{renderConnectStatus()}</div>
        </div>
    )
}

export default PatientStatusComponent