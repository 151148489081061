import React from 'react'
import { Row } from 'reactstrap'
import DoctorDeniedCompleteScreen from './doctor-denied-complete'
import DoctorDeniedScreen from './doctor-denied-screen'
import DoctorPendingScreen from './doctor-pending-screen'
import "./doctor-status.css"
import RegisterPersonalInfo from './register-personal-info'
import UniqueDoctorId from './unique-doctor-id'
import { t } from 'multi-language/multi-lang.service'
const MAX_ATTEMPTS_REGISTER_DOCTOR = 3

function RegistrationStatusPage({user, setUser}) {

    const statusScreen = () => {
      const { approvalState, registerAttempts,uniqueId} = user.data
        if (!approvalState ) return <RegisterPersonalInfo setUser={setUser} user={user} />
        if(!uniqueId) return <UniqueDoctorId setUser={setUser} user={user}/>
        if (approvalState === 'pending' ) return  <DoctorPendingScreen />
        if (approvalState === 'denied' && (!registerAttempts || registerAttempts < MAX_ATTEMPTS_REGISTER_DOCTOR)) return <DoctorDeniedScreen user={user} setUser={setUser} />
        if (approvalState === 'denied' && registerAttempts >= MAX_ATTEMPTS_REGISTER_DOCTOR) return <DoctorDeniedCompleteScreen user={user} setUser={setUser} />
    }
  return (
    <div id='doctor-registration-status' className='registration-page'>
        <Row className='pl-5 p-2 card m-0 size-3'>{t('doctor.registration.page_title')}</Row>
        {statusScreen()}
    </div>
  )
}

export default RegistrationStatusPage