import React from 'react'
import pendingImg from "../../../assets/img/theme/doctor-pending.png"
import { t } from 'multi-language/multi-lang.service'
function DoctorPendingScreen() {

  return (
      <div id='pending-page' className='pending-section card row'>
        <div className='pending-content p-2 text-center'>
          <h1 className='pending-title'>{t('doctor.registration.status.pending')}</h1>
          <img src={pendingImg} />
        </div>
      </div>
  )
}

export default DoctorPendingScreen