import React from 'react'
import deniedCompleteImage from "../../../assets/img/theme/denied-img.svg"
import { t } from 'multi-language/multi-lang.service'

function DoctorDeniedCompleteScreen() {

  return (
    <div id='denied-page' className='denied-section card row'>
    <div className='denied-content text-center'>
      <h1 className='denied-title mr-0'>{t('doctor.registration.status.denied')}</h1>
      <hr className='w-50 m-auto text-center' />
      <div className='text-center mt-3 mb-5 size-3'>{t('doctor.registration.status.contact1')} <a className='register-link mr-0' href='https://cardiacsense.zendesk.com/hc/en-us/requests/new'>{t('doctor.registration.status.contact2')}</a></div>
      <img src={deniedCompleteImage} alt='denied-img'/>
    </div>
  </div>
  )
}

export default DoctorDeniedCompleteScreen