import React from "react";
import { SUB_P_PAGES } from "components/Navbars/SubNavbarDoctor";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DoctorPannel from "./doctor-pannel";
import Dashboard from "pages/dashboard/dashboard";

const DoctorsPatientDashboard = () => {
  const { patientId, subLocation } = useParams()
  const { user, patients } = useSelector(state => state)
  const patient = patients.find(p => p.id === +patientId);

  const shouldDisplayDashboard = () => {
    return subLocation === SUB_P_PAGES.DASHBOARD
  }

  if (!patient) return null

  return (
    <div id="doctor-patient-dashboard">
      <DoctorPannel />
      {shouldDisplayDashboard() ?
        <Dashboard user={user} patient={patient} />
        : null
      }
    </div>
  )
};
export default DoctorsPatientDashboard;