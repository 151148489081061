import { updatePatients } from "store/actions";
import axios from "axios";
import ConfirmModal from "components/Modals/confirm-modal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { capitalizeWords } from "utils/formaters";
import { t } from "multi-language/multi-lang.service";
import { SUB_P_PAGES } from "./SubNavbarDoctor";

export default function DoctorsPatientBar({patient}) {
    const {user} = useSelector(state => state)
    const navigate = useNavigate()
    const [showPopUpDelete, setShowPopUpDelete] = useState(false)
    const dispatch = useDispatch()
    const fixPatient = {
        name: `${patient.firstName} ${patient.lastName}`,
        id: patient.idNumber,
        phone_number: patient.phoneNumber,
        gender: capitalizeWords(patient.gender),
        dob: patient.dateOfBirth
    }
    const handleRemove = async () =>{
        const status = 'removed-by-doctor' 
        const status_checked = false
        try {
                await axios.post(`${process.env.REACT_APP_API_URL}/app/doctor/update-relation-status`,
            {doctor_id: user.data.id, patient_id:patient.id, status},
            { headers: {'Authorization': `Bearer ${user.jwtToken}`} }) 
            await axios.post(`${process.env.REACT_APP_API_URL}/app/doctor/update-status-checked`,
            {doctor_id: user.data.id, patient_id:patient.id, status_checked},
            { headers: {'Authorization': `Bearer ${user.jwtToken}`} }) 
            dispatch( updatePatients({...patient, status}) )
            navigate('/dashboard')
        } catch (error) {
            console.log('error',error)
        }
    }

    return (
        <div id="doc-patient-navbar" style={{width:'100%', color:'#636E72'}}>
            {showPopUpDelete && 
                <ConfirmModal 
                    text={`${t('navbars.doc_patient.warning')} ${fixPatient.name} ${t('navbars.doc_patient.warning2')}?`}
                    onConfirm={handleRemove}
                    onCancel={setShowPopUpDelete}
                />
            }
            <div style={{backgroundColor:'#EBEBEB',padding:'10px',display:'flex'}}>
                <Link style={{margin:'auto 5px', color:'#636E72'}} to={`/${SUB_P_PAGES.DASHBOARD}`} >
                    <i className="fas fa-arrow-circle-left" style={{color:'#3FA842',marginRight:'7px'}}/> 
                    {t('navbars.doc_patient.back')}
                </Link>
                {Object.keys(fixPatient).map(key=>
                 <div key={key} style={{margin:'auto 25px', color:'#636E72'}}> 
                    {t(`personal_info.${key}`)}:  
                    <div id={`doc-pat-${key}`} style={{display:'inline', color:'#3FA842'}} > {fixPatient[key]} </div> 
                </div>
                )}
                <div style={{margin:'auto 11px auto auto', cursor:'pointer'}} onClick={() => setShowPopUpDelete(true)}>
                    <i className="fas fa-times-circle" style={{color:'#3FA842',marginRight:'7px'}}/>
                    {t('navbars.doc_patient.remove')}
                </div>
            </div>
        </div>
    )
}