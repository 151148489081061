import React, { useState } from 'react'
import ExpandIcon from '../../assets/img/icons/Expand.svg'
import { t } from 'multi-language/multi-lang.service'
function PasswordRestrictions() {
    const [togglePolicy, setTogglePolicy] = useState(0)

    return (
        <div id='password-restriction'>
            <div onClick={() => setTogglePolicy(1 - togglePolicy)} style={{ cursor: 'pointer' }} >
                {t('pages.login.password.title')}
                <img alt="expand"
                    src={ExpandIcon}
                    style={{ height: '15px', marginLeft: '5px', transform: togglePolicy ? 'rotate(180deg)' : undefined }} />
            </div>
            <div style={{ display: togglePolicy ? 'block' : 'none', marginLeft: '5px', fontSize: 'smaller' }} >
                <div>{`${t('pages.login.password.length')} `}</div>
                <div>{`${t('pages.login.password.capital')} [A-Z]`}</div>
                <div>{`${t('pages.login.password.small')} [a-z]`}</div>
                <div>{`${t('pages.login.password.num')} [0-9]`}</div>
                <div>{`${t('pages.login.password.special')} [!@#$%^&*}]`}</div>
            </div>
        </div>
    )
}

export default PasswordRestrictions