
import { Card, CardBody, CardTitle } from "reactstrap";
import TextTransition, { presets } from "react-text-transition";
import { timeFormatter } from "utils/formaters";
import moment from "moment";
import { MEASUREMENT_KEYS } from "consts";

export default function MeasureBox({ measurementKey, title, value, timestamp, unit, onClick, alert, disabled, innerContent, innerContentVal, bottomText }) {
    const breakPoint = innerContent ? 1600 : 1300
    const fontSize = (window.innerWidth < breakPoint) ? '28px' : '40px'
    const padding =  (window.innerWidth < breakPoint) ? '10px' : '15px'
    const opacity = disabled ? 0.2 : 1

    const emptyValue = innerContent ? '--' : '---'
    const empty = disabled || (value === emptyValue || !value || Number(value) < 1)
    if(value && typeof value === 'number') value = `${value}`
    const floatValue = !empty && typeof value === 'string' && (!Number(value.split('.')[1]) ? value.split('.')[0] : `${parseFloat(value).toFixed(1)}`)
    let displayValue = !empty ? floatValue || value : emptyValue

    const showBottomText = () => {
        if (measurementKey === MEASUREMENT_KEYS.PULSE_RATE) {
            return false
        }
        return true
    }

    const getBottomText = () => {
        if(!showBottomText()) return <span style={{opacity: 0}}>dummy</span>
        if(bottomText) return bottomText
        if((empty || !timestamp)) return emptyValue
        if(measurementKey === MEASUREMENT_KEYS.WEIGHT) return moment(timestamp).format('MMM DD')
        return timeFormatter(timestamp)
    }

    const getContent = (isSec) => {
        let iconWidth = '90%'
        let src = measurementKey
        if(innerContent){
            iconWidth = '140%'
        }
        if(isSec){
            src = innerContent.key
            unit = innerContent.unit
            displayValue = (!disabled && innerContent.value) || '--'
        }
        return(
            <div onClick={() => innerContent && onClick(src)} className="d-flex justify-content-center align-items-center" style={{ height: '100px' }} >
                <div className="col col-auto lead " style={{ fontSize, marginTop: '0px', padding: 0, color: alert ? "#f5365c" : 'inherit' }} >
                    <TextTransition id={`${measurementKey}-measure-box-value`} springConfig={presets.wobbly}>{displayValue}</TextTransition>
                </div>

                <div className="col col-auto" style={{ padding, display: 'inline-block', fontSize: '10px', paddingTop: unit ? padding : '0px' }}>
                    <img alt="title" src={require(`../../assets/img/icons/${src}.svg`)} width={iconWidth} />
                    <div id={`${src}-measure-box-unit`} className="text-center text-muted">
                        {unit}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Card className="card-stats mb-4 mb-xl-0" onClick={() => !innerContent && onClick(measurementKey)} style={Object.assign({ cursor: 'pointer' }, alert ? { border: '3px solid #f5365c' } : {})} >
            <CardBody style={{ padding: '3px 16px', opacity }} >
                <CardTitle id={`${title}-measure-box-title`} className="text-center" style={{ marginBottom: '5px' }} >
                    {title}
                </CardTitle>

                <div className={`d-flex justify-content-${innerContent ? 'between' : 'center'}`}>
                    {getContent()}
                    {innerContent &&  <><div className="m-2 divider-x"/>{getContent(true)}</>}
                </div>

                <div className="text-center text-muted">
                    <small id={`${title}-measure-box-bottom`}>
                        {getBottomText()}
                    </small>
                </div>
            </CardBody>
        </Card>
    )
}
