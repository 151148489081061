import moment from 'moment'
import DatePickerModal from 'components/Modals/DatePicker'
import { REPORTS_TITLES as reportTitles } from 'consts'
import React, { useState } from 'react'
import { Container } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { ARCHIVE_ROUTE } from 'components/Navbars/SubNavbarDoctor'

export default function MarketPlace({user, setSubRouteParam, appConfigs, role }) {
    const configuration = appConfigs?.configuration || user?.appConfigs?.configuration
    const isAfConfigOff = configuration?.alerts?.af === 'off'
    const isPwvConfigOff = configuration?.measurements?.pwv === 'off'
    const isBpConfigOff = configuration?.measurements?.blood_pressure === 'off'

    const activeReports = {
        'monthly-report': true,
        'daily-report': true,
        'blood-pressure-report': !isBpConfigOff,
        'events-report': true,
        'af-report': !isAfConfigOff,
        'pwv-report': !isPwvConfigOff,
    }
    const chooseRange = ['events-report', 'blood-pressure-report', 'pwv-report']
    const navigate = useNavigate()
    const [togglePicker, setTogglePicker] = useState(false)

    const onConfirm = (year, startDate, endDate) => {
        const startDateStr = moment(startDate).format('YYYY-MM-DD')
        const endDateStr = moment(endDate).format('YYYY-MM-DD')
        if (togglePicker === 'events-report') {
            if (role === 'doctor') {
                return setSubRouteParam(ARCHIVE_ROUTE, [startDate, endDate])
            }
            return navigate('/reports/archive/', { state: { dates: [startDate, endDate] } })
        }
        window.open(`/static/reports/${togglePicker}/${startDateStr}${endDate ? ',' + endDateStr : ''}`, "_blank")
    }

    return (
        <>
            <DatePickerModal
                isOpen={togglePicker !== false}
                toggle={setTogglePicker}
                onConfirm={onConfirm}
                choose30Days={togglePicker !== 'daily-report'}
                title={`Choose date${chooseRange.includes(togglePicker) ? 's' : ''} for ${reportTitles[togglePicker]?.title}`}
                subtitle={reportTitles[togglePicker]?.subtitle}
                selectDateAttention={reportTitles[togglePicker]?.['select-date-attention']}
                togglePicker={togglePicker}
                chooseRange={chooseRange.includes(togglePicker)}
                reportTitle = {reportTitles[togglePicker]?.title}
            />
            <Container fluid>
                <div className="profile-content-container">
                    <div style={{ marginLeft: '90px', marginTop: '70px' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {Object.keys(reportTitles).map(key =>
                                <div key={key} style={{ marginBottom: '100px', marginRight: '3vw', textAlign: 'center', cursor: activeReports[key] ? 'pointer' : 'default', opacity: activeReports[key] ? 1 : 0.3 }}
                                    onClick={() => activeReports[key] ? setTogglePicker(key) : void (0)} >
                                    <div style={{width:214,height:238,backgroundColor:'#E7F2F8',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',borderRadius:10}}>
                                        <img alt="icon" src={require(`../../assets/img/icons/${key}-icon.svg`)} />
                                        <div style={{ width: '156px', marginTop: '20px' }} >{reportTitles[key].title}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}
