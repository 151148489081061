import { setSocketPatientsData } from 'store/actions';
import { USER_TYPES } from 'consts';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useWebSocket from 'react-use-websocket';
import { setSocketConnectionId } from 'store/actions';
import { MESSAGE_TYPE } from 'consts';
import { CHANNEL_MESSAGE_ACTION } from 'consts';
import { MESSAGE_PLATFORM } from 'consts';
import { convertSocketKeys } from 'utils';

export const WebsocketPatient = ({ user }) => {
  const { sendMessage, lastMessage } = useWebSocket(`${process.env.REACT_APP_SOCKET_URL}?id=${user?.data?.id}&user_type=${user.type}&platform=portal&jwtToken=${user.jwtToken}`)
  const dispatch = useDispatch()


  useEffect(() => {
    const checkMessage = () => {
      if (lastMessage !== null) {
        const { data: massageData } = lastMessage
        try {
          const jsonData = JSON.parse(massageData)
          const { data } = jsonData
          if(!data) return
          const { onAlert, uuid_connection } = data
          if (uuid_connection) {
            invite(uuid_connection)
            return dispatch(setSocketConnectionId(uuid_connection))
          }
          if (onAlert) {//check if it is socket measurments data
            const dataConvertedKeys = convertSocketKeys(data)
            const alertsConvertedKeys = convertSocketKeys(data?.onAlert)
            dispatch( setSocketPatientsData({ [user.data.id]: {...dataConvertedKeys, onAlert: alertsConvertedKeys, isFromApp: true} })) //the key is patient id
          }
        }
        catch (error) {
          console.log('socket data is invalid json', error)
        }
      }
    }
    checkMessage()
  }, [lastMessage]);


  const invite = (uuid_connection) => {
    try {
      sendMessage(JSON.stringify({
        type: MESSAGE_TYPE.CHANNEL,
        action: CHANNEL_MESSAGE_ACTION.JOIN,
        from: {
          source_id: user.data.id,
          source_type: user.type,
          connection_id: uuid_connection,
          platform: MESSAGE_PLATFORM.PORTAL,
        },
        to: [{
          target_id: user.data.id,
          target_type: USER_TYPES.PATIENT,
          platform: MESSAGE_PLATFORM.MOBILE
        }]
      }))
    } catch (err) {
      console.log('err', err)
    }
  }

  return (<div />)
}



export default WebsocketPatient
