/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import SubNavbarDoctor, { SUB_P_PAGES } from "components/Navbars/SubNavbarDoctor";
import DoctorsPatientBar from "components/Navbars/DoctorsPatientBar";
import { useParams } from "react-router-dom";
import { setMonthlyData } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import Bell from '../../assets/img/icons/bell.svg'
import axios from "axios";
import { setupMesutments } from "consts";
import { getFirstDayInMonth } from "utils";
import Realtime from '../../components/Modals/Realtime'
import DoctorAlerts from "components/Modals/DoctorAlerts";
import { getPatientAppConfig } from "api/api-manager";
import { emptyMonthlyData } from "variables/charts";
import { t } from "multi-language/multi-lang.service";

const DoctorPannel = () => {
  const { patientId, subLocation } = useParams()
  const { user, patients, patientsConfiguration, monthlyData, measurementsFromDB } = useSelector(state => state)
  const configurations = patientsConfiguration[patientId]
  const patient = patients.find(p => p.id === +patientId);
  const dispatch = useDispatch()
  const patientMeasurments = useSelector(state => state?.socketPatientsData?.[patientId])
  const measurments = setupMesutments(patient, patientMeasurments, measurementsFromDB[patientId])
  const onAlert = measurments?.onAlert
  delete measurments?.onAlert
  const [showPopUpAlert, setShowPopUpAlert] = useState(false)
  const [alertsModal, setAlertsModal] = useState()
  const localPatientId = localStorage.getItem('currentPatientId');
  const [startRealtime, setStartRealtime] = useState(false)

  useEffect(() => {
    if (!patient || subLocation !== SUB_P_PAGES.DASHBOARD) return
    const fetchData = async () => {
      if (configurations && patientId === localPatientId) return
      localStorage.setItem('currentPatientId', patientId)

      if (!configurations) {
        getPatientAppConfig(patient.id)
      }
      getPatientMonthly()
    }
    fetchData()
    if (patient?.alerts.length) {
      setAlertsModal(patient?.alerts)
    }
  }, [patient])

  useEffect(() => {
    if (startRealtime) {
      axios.post(`${process.env.REACT_APP_API_URL}/app/notifications/request-realtime`, { patientId: +patientId, doctorName: `${user.data.firstName} ${user.data.lastName}` }, { headers: { 'Authorization': `Bearer ${user.jwtToken}` } })
        .then(() => {
          console.log('notification sent to patient')
        })
        .catch(err => {
          console.log('error fetching monthly data 1', err)
        })
    }

  }, [startRealtime]);

  function getPatientMonthly() {
    const day = getFirstDayInMonth()
    if (monthlyData?.[patientId]?.[day]) return
    axios.post(`${process.env.REACT_APP_API_URL}/app/doctor/get-patient-monthly`, { day, patientId: +patientId }, { headers: { 'Authorization': `Bearer ${user.jwtToken}` } })
      .then(res => {
        const data = res.data || emptyMonthlyData(day)
        dispatch(setMonthlyData(data, day, false, patientId))
      })
      .catch(err => {
        console.log('error fetching monthly data 1', err)
        dispatch(setMonthlyData({}, day, false, patientId))
      })
  }

  if (!patient) return (
    <div className="lead text-center w-100" >
      <div style={{ height: '93px' }} />
    </div>
  )

  const openModal = () => {
    setStartRealtime(true)
  }

  return (
    <div id="doctor-pannel">
      {startRealtime &&
        <Realtime toggleModal={setStartRealtime} patient={patient} onAlert={onAlert} configurations={configurations} />
      }
      {patient?.alerts.length > 0 && <DoctorAlerts alerts={alertsModal} patient={patient} toggleModal={setAlertsModal} user={user} alertsOrRemind={'alerts'} isRealtime={startRealtime} />}
      {showPopUpAlert && <DoctorAlerts alerts={patient?.remindAlerts} patient={patient} toggleModal={setShowPopUpAlert} user={user} alertsOrRemind={'remindAlerts'} />}
      <DoctorsPatientBar patient={patient} />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, marginLeft: 20, marginRight: 20 }}>
        <Button outline color="blue" type="button" style={{ padding: '0.325rem 1.05rem', borderRadius: 35, cursor: 'pointer' }} onClick={openModal}>
          {t('modals.realtime.request')}
        </Button>
        {!!patient?.remindAlerts?.length && <img style={{ cursor: 'pointer', right: 0 }} src={Bell} onClick={() => setShowPopUpAlert(true)} alt='remind me' />}
      </div>
      <SubNavbarDoctor appConfigs={configurations} subLocation={subLocation} patient={patient} jwtToken={user?.jwtToken} />
    </div>
  )
};
export default DoctorPannel;